import { MessageTemplateList } from '../components/MessageTemplate';

function MessageTemplatesRoute() {
  return (
    <div className='w-full h-full flex flex-col text-white px-10 pb-10'>
      <MessageTemplateList />
    </div>
  );
}

export const Component = MessageTemplatesRoute;

export function clientLoader() {
  return null;
}
