import { useMemo } from 'react';
import { Waypoint } from 'react-waypoint';

import { getFeatureQueryParam } from '../../hooks/useFeatureQueryParam';
import { useInstance } from '../../hooks/useInstance';
import { useListLoader } from '../../hooks/useListLoader';
import { apiService } from '../../services/api-service';
import { type MessageTemplatesResponse } from '../../services/api-service/messageTemplate.api';
import { type MessageTemplate } from '../../types/messageTemplate';
import { ErrorMessage } from '../Game/GameCenter';
import { Loading } from '../Loading';
import { MessageTemplateActionSheet } from './ActionSheet';
import {
  useTriggerCreateMessageTemplateModal,
  useTriggerDeleteMessageTemplateModal,
  useTriggerEditMessageTemplateModal,
} from './Modals';
import { MessageTemplateTranslator } from './Translated';
import { MOCK_MESSAGE_VARIABLES } from './types';

export function MessageTemplateItem(props: {
  template: MessageTemplate;
  onEdit: () => void;
  onDelete: () => void;
}): JSX.Element {
  const { template, onEdit, onDelete } = props;
  return (
    <div className='w-5/6 border border-secondary rounded-xl h-100 scrollbar overflow-auto my-6 p-6'>
      <div className='flex items-center justify-between'>
        <div className='text-xl font-bold'>{template.title}</div>
        <div className='flex'>
          <button
            type='button'
            className='btn text-primary underline mx-1'
            onClick={() => onEdit && onEdit()}
          >
            Edit
          </button>
          <button
            type='button'
            className='btn text-red-002 underline mx-1'
            onClick={() => onDelete && onDelete()}
          >
            Delete
          </button>
        </div>
      </div>
      <pre className='mt-4 text-sm whitespace-pre-wrap font-sans'>
        {template.body}
      </pre>
    </div>
  );
}

export function MessageTemplateRow(props: {
  template: MessageTemplate;
  onEdit: () => void;
  onDelete: () => void;
}): JSX.Element {
  const { template, onEdit, onDelete } = props;
  return (
    <div className='w-5/6 border border-secondary rounded-xl h-100 scrollbar overflow-auto my-6 p-6'>
      <div className='flex items-center justify-between'>
        <div className='text-xl font-bold'>{template.title}</div>
        <div className='flex'>
          <button
            type='button'
            className='btn text-primary underline mx-1'
            onClick={() => onEdit && onEdit()}
          >
            Edit
          </button>
          <button
            type='button'
            className='btn text-red-002 underline mx-1'
            onClick={() => onDelete && onDelete()}
          >
            Delete
          </button>
        </div>
      </div>
      <pre className='mt-4 text-sm whitespace-pre-wrap font-sans'>
        {template.body}
      </pre>
    </div>
  );
}

export function MessageTemplateList(): JSX.Element | null {
  const paginator = useMemo(() => apiService.messageTemplate.query(), []);
  const { items, dao, state, error, handleLoadMore, handleRetry } =
    useListLoader<MessageTemplatesResponse, MessageTemplate>(
      paginator,
      (a, b) => a.id === b.id
    );

  const triggerCreateModal = useTriggerCreateMessageTemplateModal();
  const triggerEditModal = useTriggerEditMessageTemplateModal();
  const triggerDeleteModal = useTriggerDeleteMessageTemplateModal();
  const copyDebugEnabled = useInstance(() =>
    getFeatureQueryParam('message-template-copy-debug')
  );

  const handleCreate = () => {
    triggerCreateModal((template) => {
      dao.addItem(template);
    });
  };

  const handleEdit = (template: MessageTemplate) => {
    triggerEditModal(template, (updated) => {
      dao.updateItem(updated);
    });
  };

  const handleDelete = (template: MessageTemplate) => {
    triggerDeleteModal(template, () => {
      dao.deleteItem(template);
    });
  };

  const showEmptyMsg =
    state.isDone && !error && items.length === 0 && !paginator.hasMore();
  const canLoadMore = state.isDone && !error && paginator.hasMore();

  return (
    <div className='w-full'>
      <div className='flex text-white justify-between'>
        <p className='text-2xl font-medium'>Message Templates</p>
        <button
          type='button'
          className='btn-primary w-42 h-10 flex justify-center items-center'
          onClick={handleCreate}
        >
          Create Template
        </button>
      </div>

      <table className='w-full mt-10'>
        <thead>
          <tr className='text-left h-14'>
            <th>Title</th>
            <th>Campaigns</th>
            <th>Messages Sent</th>
            <th>Recipients</th>
            <th>Reactions/Replies</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {items.map((i) => (
            <tr
              className='text-left cursor-pointer hover:bg-lp-gray-002 h-10 text-sm'
              onClick={() => handleEdit(i)}
            >
              <th className='w-1/5 truncate font-normal'>{i.title}</th>
              <th className='w-1/4 font-normal'>
                {[
                  ...new Set(
                    i.messageLogics?.map((l) => l.messageCampaign?.name)
                  ),
                ].join(', ')}
              </th>
              <th className='font-normal'>{i.messagesSentCount}</th>
              <th className='font-normal'>{i.recipientsCount}</th>
              <th className='font-normal'>{i.reactionsCount}</th>
              <th className='font-normal' onClick={(e) => e.stopPropagation()}>
                <MessageTemplateActionSheet
                  onDelete={() => handleDelete(i)}
                  onEdit={() => handleEdit(i)}
                />
              </th>
            </tr>
          ))}
        </tbody>
      </table>

      <div>
        {state.isRunning && (
          <div className='flex items-center justify-center mt-50'>
            <Loading />
          </div>
        )}
        {error && (
          <div className='w-full flex items-center justify-center text-white mt-50'>
            <ErrorMessage
              text='Something went wrong'
              handleRetry={handleRetry}
            />
          </div>
        )}
        {showEmptyMsg && (
          <div className='w-full flex items-center justify-center text-white mt-50'>
            No Message Templates.
          </div>
        )}
        {canLoadMore && (
          <Waypoint onEnter={handleLoadMore} fireOnRapidScroll>
            <div>&nbsp;</div>
          </Waypoint>
        )}
      </div>

      {copyDebugEnabled && (
        <MessageTemplateTranslator vars={MOCK_MESSAGE_VARIABLES} />
      )}
    </div>
  );
}
